import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import VueLazyLoad from 'vue-lazyload'
const VueScrollTo = require('vue-scrollto')

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)
Vue.use(VueScrollTo)

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

import '@/utils/puppeteer';


Vue.use(VueScrollTo, {
  container: 'body',   // 滚动元素
  easing: 'ease',  // 动画曲线
  offset: 200,  // 滚动终点距离父元素顶部距离
  force: true,  // 强制立即执行，即便元素是可见的
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false, // x 轴禁止滚动
  y: true
})

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.scrollTo(0, 0);
  if(to.meta.title) {
    document.title = to.meta.title + '-洁知宝'
  }
  next();
  })

Vue.config.productionTip = false
Vue.use(VueLazyLoad,{
  error:'./assets/logo.png',
  loading:'./asset/logo.png'
})

Vue.use(ElementUI);



new Vue({
  router,
  store,
  render: h => h(App),
  // mounted () {
  //   document.dispatchEvent(new Event('render-event'))
  //   }
}).$mount('#app')
